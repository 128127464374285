import React from "react";
import "./css/Home.css";
import {Avatar, Box, Button, IconButton, ListItemIcon, Menu, MenuItem, Tooltip} from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import HomeIcon from "@mui/icons-material/Home";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PeopleIcon from "@mui/icons-material/People";
import InfoIcon from '@mui/icons-material/Info';
import TranslateIcon from "@mui/icons-material/Translate";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Carousel from "react-material-ui-carousel";
import {CarouselItem} from "../CarouselItem/CarouselItem";
import { v4 as uuid } from "uuid";
import {Translate, translate} from "react-i18nify";

export interface IPriceListItem {
    service: string,
    price: string
}

export const Home: React.FC<any> = ({ language, languages, changeLanguage }) => {
    const social: string = 'Linkedin';

    const navbarItems: { [navItemKey: string]: string } = {
        [translate('navbar.home')]: 'header',
        [translate('navbar.offers')]: 'what-we-offer',
        [translate('navbar.prices')]: 'price-list',
        [translate('navbar.clients')]: 'clients',
        [translate('navbar.about-us')]: 'about-us'
    };
    const navbarEntries: Array<[string, string]> = Object.entries(navbarItems);

    const clients: Array<{ src: string }> = [
        {
            src: '/images/clients/carbon-logo.svg'
        },
        {
            src: '/images/clients/polikli3.jpg'
        },
        {
            src: '/images/clients/nysp.png'
        },
        {
            src: '/images/clients/hosp3.png'
        }
    ];

    const priceItems: Array<IPriceListItem> = [
        {
            service: translate('price-list-section.it-advice'),
            price: translate('price-list-section.it-advice-price')
        },
        {
            service: translate('price-list-section.software-development'),
            price: translate('price-list-section.software-development-price')
        },
        {
            service: translate('price-list-section.website-enhancement'),
            price: translate('price-list-section.website-enhancement-price')
        },
        {
            service: translate('price-list-section.website-support'),
            price: translate('price-list-section.website-support-price')
        },
        {
            service: translate('price-list-section.website-creation'),
            price: translate('price-list-section.website-creation-price')
        },
        {
            service: translate('price-list-section.gas-station-network-integration'),
            price: translate('price-list-section.agrees')
        },
        {
            service: translate('price-list-section.it-outsource'),
            price: translate('price-list-section.agrees')
        },
        {
            service: translate('price-list-section.other'),
            price: translate('price-list-section.agrees')
        }
    ];

    const serviceItems: Array<string> = [
        translate('service-items.network-integration'),
        translate('service-items.software-interface'),
        translate('service-items.support'),
        translate('service-items.accounting'),
        translate('service-items.software-update'),
        translate('service-items.accounting-automation'),
        translate('service-items.network-settings'),
        translate('service-items.website')
    ];

    const scrollToSection = (id: string): void => {
        const element: Element = document.querySelector(`#${id}`) as Element;
        const y: number = element.getBoundingClientRect().top + window.scrollY;

        window.scroll({
            top: y,
            behavior: 'smooth'
        });
    };

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open: boolean = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>): void => setAnchorEl(event.currentTarget);
    const handleClose = (): void => setAnchorEl(null);

    const getMenuIconByAnchor = (anchor: string): any => {
        switch (anchor) {
            case 'header':
                return <HomeIcon fontSize="small" />;
            case 'what-we-offer':
                return <LocalOfferIcon fontSize="small" />;
            case 'price-list':
                return <AttachMoneyIcon fontSize="small" />;
            case 'clients':
                return <PeopleIcon fontSize="small" />;
            case 'about-us':
                return <InfoIcon fontSize="small" />;
        }
    };

    return (
        <div className="Home">
            <nav className={"navigation-bar"}>
                <div className={"navigation-bar-menu"}>
                    <div className={"navigation-bar-menu-items"}>
                        <Button onClick={() => scrollToSection(navbarEntries[0][1])}>
                            { navbarEntries[0][0] }
                        </Button>
                        <div>
                            {
                                navbarEntries?.slice(1)?.map(([itemName, anchor]: [string, string]) => (
                                    <Button onClick={() => scrollToSection(anchor)}
                                            key={uuid()}>
                                        { itemName }
                                    </Button>
                                ))
                            }
                        </div>
                    </div>
                </div>

                {/*small resolution burger menu*/}

                <div className={'navigation-bar-menu-wrapper'}>
                    <Box className={'navigation-bar-menu-burger'}
                         sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                        <Tooltip title="Account settings">
                            <IconButton
                                onClick={handleClick}
                                size="small"
                                sx={{ ml: 2 }}
                                aria-controls={open ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                            >
                                <Avatar sx={{ width: 32, height: 32 }}>
                                    { !open ?
                                        <MenuIcon fontSize="small" />
                                        :
                                        <CloseIcon fontSize="small" />
                                    }
                                </Avatar>
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <MenuItem className={'navigation-bar-menu-wrapper-localization-btn'}
                              onClick={(event: React.MouseEvent<HTMLLIElement, MouseEvent>): void => {
                                  event.stopPropagation();
                                  changeLanguage(languages?.filter((lan: string): boolean => lan !== language)[0] as any);
                              }}>
                        <ListItemIcon>
                            <TranslateIcon fontSize="small" />
                        </ListItemIcon>
                        <div className={'localization'}>
                            { languages?.filter((lan: string): boolean => lan !== language)[0].toUpperCase() }
                        </div>
                    </MenuItem>
                </div>
                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                left: 35,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0
                            }
                        }
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    {
                        navbarEntries?.map(([itemName, anchor]: [string, string]) => (
                            <MenuItem onClick={(): void => { handleClose(); scrollToSection(anchor); }}
                                      key={uuid()}>
                                <ListItemIcon>
                                    { getMenuIconByAnchor(anchor) }
                                </ListItemIcon>
                                { itemName }
                            </MenuItem>
                        ))
                    }
                </Menu>
            </nav>
            <section className={"block header"}
                     id={"header"}>
                <div className={"sub-block"}>
                    <img src="/images/it-service.jpg"
                         alt="it service"
                         className={"block--img"}
                    />
                </div>
                <div className={"sub-block"}>
                    <h1>
                        <Translate value={'title'} />
                    </h1>
                    <h1 className={"animated uppercase"}>
                        ― { translate('subtitle') }
                    </h1>
                    <div className="block--social-media">
                        <Button>
                            <a href="https://www.linkedin.com/in/serhii-tarasenko-38b20251/"
                               target="_blank"
                               className={"social-link"}>
                                <LinkedInIcon />
                                { social }
                            </a>
                        </Button>
                    </div>
                </div>
            </section>
            <section className="block"
                     id={"what-we-offer"}>
                <div className={"sub-block"}>
                    <h5 className={"uppercase"}>
                        ― { translate('our-offers') }
                    </h5>
                    <ul>
                        {
                            serviceItems?.map((item: string) => <li key={uuid()}>{ item }</li>)
                        }
                    </ul>
                </div>
            </section>
            <section className="block"
                     id={"price-list"}>
                <div className={"sub-block"}>
                    <h5 className={"uppercase"}>
                        ― { translate('price-list-section.title') }
                    </h5>
                    <table>
                        <tbody>
                            {
                                priceItems?.map(({ service, price }: IPriceListItem) => (
                                    <tr key={uuid()}>
                                        <td>{ service }</td>
                                        <td>{ price }</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </section>
            <section className="block"
                     id={"clients"}>
                <div className={"sub-block"}>
                    <h5 className={"uppercase"}>
                        ― { translate('our-clients') }
                    </h5>
                    <Carousel className={"custom-carousel"}
                              navButtonsWrapperProps={{ // aligning nav buttons
                                  style: {
                                      bottom: '0',
                                      top: '-40px'
                                  }
                              }}
                              stopAutoPlayOnHover={true}
                              swipe={true}
                              navButtonsAlwaysVisible={true}
                              height={300}
                    >
                        {
                            clients?.map((item: {src: string}) => <CarouselItem key={uuid()} item={item} />)
                        }
                    </Carousel>
                </div>
            </section>
            <section className="block"
                     id={"about-us"}>
                <div className={"sub-block"}>
                    <h5 className={"uppercase"}>
                        ― { translate('navbar.about-us') }
                    </h5>
                    <span>
                        <Translate value={'about-us-section.order-services'} />
                    </span>
                    <br/>
                    <span>
                        <Translate value={'about-us-section.description'} />
                    </span>
                </div>
            </section>
        </div>
    )
}
