import React, {Component, Suspense} from "react";
import {HashRouter, Routes, Route} from "react-router-dom";
import "./App.css";
import {Home} from "./components/Home/Home";
import {setTranslations, setLocale} from "react-i18nify";
import en from "../src/data/i18/en.json";
import ua from "../src/data/i18/ua.json";

export default class App extends Component<any, any> {
  languages: Array<string> = ['en', 'ua'];

  constructor(props: any){
    super(props);

    this.state = {
      language: 'en'
    };
  }

  changeLanguage(language: 'en' | 'ua' = 'en'): void {
    setLocale(language);
    this.setState({ language });
  }

  componentDidMount(): void {
    const translations: { en: Object; ua: Object } = {
      en: JSON.parse(JSON.stringify(en)),
      ua: JSON.parse(JSON.stringify(ua))
    };

    setTranslations(translations);
    this.changeLanguage();
  }

  render() {
    return (
        <HashRouter basename={window.location.pathname || ' '} >
          <div className="App">
            {/*   language overlay   */}
            <div className="language-overlay-section">
                <span className={this.state.language === this.languages[0] ? 'selected' : ''}
                      onClick={() => this.changeLanguage(this.languages[0] as any)}>
                    { this.languages[0].toUpperCase() }
                </span>
                <span className={this.state.language === this.languages[1] ? 'selected' : ''}
                    onClick={() => this.changeLanguage(this.languages[1] as any)}>
                    { this.languages[1].toUpperCase() }
                </span>
            </div>
            {/*   ---------------   */}
            <div className="App--container">
              <Suspense fallback={ <></> }>
                <Routes>
                  <Route path={'/'} element={
                    <Home language={this.state.language}
                          languages={this.languages}
                          changeLanguage={this.changeLanguage.bind(this)} />
                  } />
                  {/*<Route element={ErrorPage} />*/}
                </Routes>
              </Suspense>
            </div>
          </div>
        </HashRouter>
    );
  }
}
