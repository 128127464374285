import React from "react";
import "./css/CarouselItem.css";
import {Paper} from "@mui/material";

export const CarouselItem: React.FC<any> = ({item}: any) => {

    return (
        <div className="CarouselItem">
            <Paper className={"paper"}>
                <img src={item.src} alt=""/>
            </Paper>
        </div>
    );
}
